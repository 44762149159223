import ModalVideo from './index';
import Composition from 'src/lib/Composition';
import { formatModalId } from 'src/lib/helpers';
import data from './data';

const { component, compositionFunction } = Composition(ModalVideo)<typeof data>(
  ({ fields, rendering }) => {
    const modalVideo = fields?.items?.[0];

    return {
      closedCaptions: modalVideo?.fields?.['Enable Closed Captions']?.value,
      fullScreen: modalVideo?.fields?.['Allow Full Screen']?.value,
      headline: modalVideo?.fields?.Headline,
      id: formatModalId(rendering?.dataSource),
      intro: modalVideo?.fields?.Intro,
      modestBranding: modalVideo?.fields?.['Hide Youtube Icon']?.value,
      relatedVideos: modalVideo?.fields?.['Enable Related Videos']?.value,
      url: modalVideo?.fields?.Video?.value?.url,
    };
  }
);

export { compositionFunction };
export default component;
